<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <!--<el-button type="primary" plain size="mini" v-if="roleApprovalSearchFlag" @click="roleApprovalSearch">查询</el-button>-->
                        <el-button type="primary" plain size="mini" v-if="roleApprovalAddFlag" @click="roleApprovalAdd">新增</el-button>
                        <el-button type="primary" plain size="mini" v-if="roleApprovalModifyFlag" @click="roleApprovalModify">修改</el-button>
                        <el-button type="primary" plain size="mini" v-if="roleApprovalDelFlag" @click="roleApprovalDel">删除</el-button>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-input maxlength="50" placeholder="请输入角色名称（模糊匹配）" v-model="AccurateAndUnique_roleName" size="mini" style="width: 250px"  class="input-with-input">
                            <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
                        </el-input>
                        <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
                    </div>
                </el-col>
                <el-drawer
                        title="高级查询条件"
                        :before-close="cancelForm"
                        :visible.sync="dialog"
                        direction="rtl"
                        custom-class="demo-drawer"
                        ref="drawer"
                >
                    <div class="demo-drawer__content">
                        <el-row>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div>
                                            <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                                                <el-form-item label="用户角色id" prop="roleId">
                                                    <el-input  maxlength="18" v-model="dataForms.roleId"></el-input>
                                                </el-form-item>
                                                <el-form-item label="枚举名称" prop="roleEnum">
                                                    <el-select v-model="dataForms.roleEnum" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="admin" value="admin"></el-option>
                                                        <el-option label="customer" value="customer"></el-option>
                                                        <el-option label="driver" value="driver"></el-option>
                                                        <el-option label="carrier" value="carrier"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="角色所属模块" prop="moduleId">
                                                    <el-select v-model="dataForms.moduleId" placeholder="请选择" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="系统管理" value="0"></el-option>
                                                        <el-option label="城市配" value="1"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="是否启用" prop="status">
                                                    <el-select v-model="dataForms.status" placeholder="请选择" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="冻结" value="0"></el-option>
                                                        <el-option label="启用" value="1"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="是否管理正泰业务" prop="chintBusiness">
                                                    <el-select v-model="dataForms.chintBusiness" placeholder="请选择" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="管正泰业务" value="0"></el-option>
                                                        <el-option label="不管正泰业务" value="1"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="是否管理外阜" prop="outGoing">
                                                    <el-select v-model="dataForms.outGoing" placeholder="请选择" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="管外阜" value="0"></el-option>
                                                        <el-option label="管市内" value="1"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="区域级别" prop="regionLevel">
                                                    <el-select v-model="dataForms.regionLevel" placeholder="请选择" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="省级" value="1"></el-option>
                                                        <el-option label="市级" value="2"></el-option>
                                                        <el-option label="区级" value="3"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-form>
                                            <div slot="footer" class="dialog-footer" style="text-align: center">
                                                <!--<el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
                                                <el-button size="mini" v-if="roleApprovalSearchFlag" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查 询' }}</el-button>
                                                <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>

                        </el-row>
                    </div>
                </el-drawer>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    @handleClosButtonClick="handleClosButtonClick"
                    ref='cesTable'
            >
            </ces-table>
        </div>
        <div>
            <el-dialog
                    title="分配菜单"
                    :visible.sync="dialogVisibleMenu"
                    :close-on-click-modal="false"
                    :before-close="tree_handleClose"
                    v-if="addDialogVisible"
                    width="30%">

                <el-tree
                        :data="menuTreeList"
                        show-checkbox
                        check-strictly
                        node-key="menuId"
                        ref="tree"
                        @check-change="checkChange"
                        highlight-current
                        :default-expanded-keys=expandTreeNodesByDefault
                        :props="defaultProps">
                </el-tree>
                <div style="margin-top: 20px" align="center">
                    <el-button type="primary" @click="handleSaveMenu()">保存</el-button>
                    <el-button @click="handleClearMenu()">清空</el-button>
                </div>
            </el-dialog>
        </div>
        <el-dialog
                :title="dialogroleAPPovaltitile"
                :visible.sync="dialog_roleAPPoval"
                width="75%"
                :modal="true"
                center
                :before-close="handleClose_roleAPPoval">
            <el-card class="box-card" :body-style="{ padding: '10px' }">
                <div slot="header">
                    <span style="font-size: 14px">角色信息</span>
                </div>
                <div>
                    <el-form :model="roleAPPovalForm"  ref="roleAPPovalForm" :rules="roleAPPovalFormrules" label-width="110px" class="demo-ruleForm" size="mini" label-position="center">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="角色名称" prop="roleName">
                                    <el-input v-model="roleAPPovalForm.roleName" style="width: 193px;"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="枚举名称" prop="roleEnum">
                                    <el-select v-model="roleAPPovalForm.roleEnum" :disabled="roleEnumFlag">
                                        <el-option label="admin" value="admin"></el-option>
                                        <el-option label="customer" value="customer"></el-option>
                                        <el-option label="driver" value="driver"></el-option>
                                        <el-option label="carrier" value="carrier"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="是否启用" prop="status">
                                    <el-select v-model="roleAPPovalForm.status">
                                        <el-option label="启用" value="1"></el-option>
                                        <el-option label="冻结" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="是否管理正泰" prop="chintBusiness">
                                    <el-select v-model="roleAPPovalForm.chintBusiness">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="管正泰业务" value="0"></el-option>
                                        <el-option label="不管正泰业务" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="是否管理外阜" prop="outGoing">
                                    <el-select v-model="roleAPPovalForm.outGoing">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="管外阜" value="0"></el-option>
                                        <el-option label="管市内" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="区域级别" prop="regionLevel">
                                    <el-select v-model="roleAPPovalForm.regionLevel">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="省级" value="1"></el-option>
                                        <el-option label="市级" value="2"></el-option>
                                        <el-option label="区级" value="3"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="角色所属模块" prop="moduleId">
                                    <el-select v-model="roleAPPovalForm.moduleId">
                                        <el-option label="系统管理" value="0"></el-option>
                                        <el-option label="城市配" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="角色描述" prop="description">
                                    <el-input  auto-complete="off" v-model="roleAPPovalForm.description" style="width: 193px;"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="save_roleAPPoval">提 交</el-button>
                <el-button @click="handleClose_roleAPPoval">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    //引入组件
    import cesTable from '../../components/common/table.vue'
    import Drawer from '../../components/common/drawer.vue'
    import axios from 'axios'
    import arrayQuery from '../../components/arrayQuery.js'
    import qs from 'qs'
    import 'element-ui/lib/theme-chalk/index.css'
    export default {
        data(){
            return {
                table: false,
                dialog: false,
                loading: false,
                dialogroleAPPovaltitile:'',
                dialog_roleAPPoval:false,
                roleEnumFlag:false,
                AccurateAndUnique_roleName:'',
                dataForms:{
                    roleName:'',
                    roleId:'',
                    roleEnum:'',
                    moduleId:'',
                    status:'',
                    chintBusiness:'',
                    outGoing:'',
                    regionLevel:''
                },
                roleAPPovalForm:{
                    roleId:'',
                    roleName: '',
                    roleEnum:'',
                    description:'',
                    moduleId:'',
                    chintBusiness:'',
                    outGoing:'',
                    regionLevel:'',
                    status:''
                },
                roleAPPovalFormrules:{
                    roleName: [
                        { required: true,message: '请输入角色名称', trigger: 'blur' }
                    ],
                    roleEnum: [
                        { required: true,message: '请输入枚举名称', trigger: 'blur' }
                    ],
                    moduleId: [
                        { required: true,message: '请输入角色所属模块', trigger: 'blur' }
                    ],
                    description:[
                        { required: true,message: '请输入角色描述', trigger: 'blur' }
                    ]
                },
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    {label:'角色id',prop:'roleId',width:'120',align:'center'},
                    {label:'角色名称',prop:'roleName',width:'200',align:'center'},
                    {label:'启用状态',prop:'status',align:'center',
                        formatter: (row) => {
                            if (row.status == 1) {
                                return "启用";
                            } else if (row.status == 0) {
                                return "冻结";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'角色枚举名称',prop:'roleEnum',align:'center',width:'120'},
                    {label:'角色所属模块',prop:'moduleId',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.moduleId == 1) {
                                return "城市配";
                            } else if (row.moduleId == 0) {
                                return "系统管理";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'角色描述',prop:'description',align:'center',width:'160'},
                    {label:'是否管理正泰业务',prop:'chintBusiness',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.chintBusiness == 1) {
                                return "不管正泰业务";
                            } else if (row.chintBusiness == 0) {
                                return "管正泰业务";
                            } else {
                                return "管全部";
                            }
                        }
                    },
                    {label:'是否管理外阜',prop:'outGoing',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.outGoing == 1) {
                                return "管市内";
                            } else if (row.outGoing == 0) {
                                return "管外阜";
                            } else {
                                return "管全部";
                            }
                        }
                    },
                    {label:'区域级别',prop:'regionLevel',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.regionLevel == 1) {
                                return "省级";
                            } else if (row.regionLevel == 2) {
                                return "市级";
                            }else if (row.regionLevel == 3) {
                                return "区级";
                            } else {
                                return "管全部";
                            }
                        }
                    },
                    {label:'创建人',prop:'creator',align:'center',width:'120'},
                    {label:'修改人',prop:'modifier',align:'center'},
                    {label:'创建时间',prop:'createTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.createTime == null){
                                return ''
                            }else {
                                return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {label:'修改时间',prop:'modifyTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.modifyTime == null){
                                return ''
                            }else {
                                return this.$moment(row.modifyTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {
                        id: "button",
                        type: "Button",
                        label: "操作",
                        fixed: "right",
                        width: "120",
                        prop: "",
                        align: "center",
                        btnList: [
                            {
                                size:'mini',
                                type:'primary',
                                id: "stopSingle",
                                label: "添加权限",
                            }
                        ]
                    },
                ],
                pagination:{ //分页数据
                    pageSize:10,
                    pageNum:1,
                    total:0
                },
                merge:[],
                dialogVisibleMenu:false,
                menuTreeList:[],
                defaultProps: {
                    children: 'children',
                    label: 'menuName'
                },
                roleId:null,
                roleApprovalSearchFlag:true,
                roleApprovalAddFlag:true,
                roleApprovalModifyFlag:true,
                roleApprovalDelFlag:true,
                expandTreeNodesByDefault:[],
                addDialogVisible:false
            }
        },

        components:{ //引入组件后注册组件
            cesTable,
            Drawer
        },
        mounted(){
            this.getTableAndForm();
            this.getMenuTree();
            let parentId = '1_2';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let roleApprovalSearch = currentPageButtons.find(item => {
                return item.menuId == '1_2_1';
            });
            if(roleApprovalSearch == undefined){
                _this.roleApprovalSearchFlag = false;
            }else {
                _this.roleApprovalSearchFlag = true;
            }
            //新增按钮权限
            let roleApprovalAdd = currentPageButtons.find(item => {
                return item.menuId == '1_2_2';
            });
            if(roleApprovalAdd == undefined){
                _this.roleApprovalAddFlag = false;
            }else {
                _this.roleApprovalAddFlag = true;
            }
            //修改按钮权限
            let roleApprovalModify = currentPageButtons.find(item => {
                return item.menuId == '1_2_3';
            });
            if(roleApprovalModify == undefined){
                _this.roleApprovalModifyFlag = false;
            }else {
                _this.roleApprovalModifyFlag = true;
            }
            //删除按钮权限
            let roleApprovalDel = currentPageButtons.find(item => {
                return item.menuId == '1_2_4';
            });
            if(roleApprovalDel == undefined){
                _this.roleApprovalDelFlag = false;
            }else {
                _this.roleApprovalDelFlag = true;
            }
        },
        methods:{
            tree_handleClose(){
                this.expandTreeNodesByDefault.length = 0;
                this.dialogVisibleMenu = false;
                this.addDialogVisible = false;
            },
            checkChange(data, check) {
                // 父节点操作
                if (data.parentId !== null) {
                    if (check === true) {
                        // 如果选中，设置父节点为选中
                        this.$refs.tree.setChecked(data.parentId, true);
                    } else {
                        // 如果取消选中，检查父节点是否该取消选中（可能仍有子节点为选中状态）
                        var parentNode = this.$refs.tree.getNode(data.parentId);
                        var parentHasCheckedChild = false;
                        for (
                            var i = 0, parentChildLen = parentNode.childNodes.length;
                            i < parentChildLen;
                            i++
                        ) {
                            if (parentNode.childNodes[i].checked === true) {
                                parentHasCheckedChild = true;
                                break;
                            }
                        }
                        if (!parentHasCheckedChild)
                            this.$refs.tree.setChecked(data.parentId, false);
                    }
                }
                // 子节点操作，如果取消选中，取消子节点选中
                if (data.children != null && check === false) {
                    for (var j = 0, len = data.children.length; j < len; j++) {
                        var childNode = this.$refs.tree.getNode(data.children[j].menuId);
                        if (childNode.checked === true) {
                            this.$refs.tree.setChecked(childNode.data.menuId, false);
                        }
                    }
                }
            },
            save_roleAPPoval(){
                this.$refs["roleAPPovalForm"].validate(valid => {
                    if (valid) {

                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });
                const data = new URLSearchParams();
                data.append('roleId', this.roleAPPovalForm.roleId);
                data.append('roleName', this.roleAPPovalForm.roleName);
                data.append('roleEnum', this.roleAPPovalForm.roleEnum);
                data.append('description', this.roleAPPovalForm.description);
                data.append('moduleId', this.roleAPPovalForm.moduleId);
                data.append('chintBusiness', this.roleAPPovalForm.chintBusiness);
                data.append('outGoing', this.roleAPPovalForm.outGoing);
                data.append('regionLevel', this.roleAPPovalForm.regionLevel);
                data.append('status', this.roleAPPovalForm.status);
                if( this.dialogroleAPPovaltitile == '新增角色'){
                    //运单新增
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/pm/role/addRole`,
                        data:data
                    }).then(res=> {
                        if(res.data.success == true){
                            this.$message({
                                message: '新增成功',
                                type: 'success'
                            });
                            this.handleClose_roleAPPoval();
                            this.roleApprovalSearch();
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                    });
                }else if( this.dialogroleAPPovaltitile == '修改角色'){
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/pm/role/updateRole`,
                        data:data
                    }).then(res=> {
                        if(res.data.success == true){
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });
                            this.handleClose_roleAPPoval();
                            this.roleApprovalSearch();
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                    });
                }
            },
            handleClose_roleAPPoval(){
                this.dialog_roleAPPoval = false;
                this.$refs.roleAPPovalForm.resetFields();
                this.roleApprovalSearch();
            },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.roleApprovalSearch()
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.roleApprovalSearch()
            },
            handleQuery(){ //查询
                this.pagination.pageSize = 10;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.loading = true;
                const data = new URLSearchParams();
                data.append('roleName', this.AccurateAndUnique_roleName);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/role/queryRolesPage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            //查询
            roleApprovalSearch(){
                this.loading = true;
                const data = new URLSearchParams();
                data.append('roleName', this.dataForms.roleName);
                data.append('roleId', this.dataForms.roleId);
                data.append('roleEnum', this.dataForms.roleEnum);
                data.append('moduleId', this.dataForms.moduleId);
                data.append('status', this.dataForms.status);
                data.append('chintBusiness', this.dataForms.chintBusiness);
                data.append('outGoing', this.dataForms.outGoing);
                data.append('regionLevel', this.dataForms.regionLevel);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/role/queryRolesPage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            roleApprovalSearch_Multiple(){
                this.pagination.pageSize = 10;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.roleApprovalSearch();
            },
            //新增
            roleApprovalAdd(){
                this.dialogroleAPPovaltitile = '新增角色';
                this.dialog_roleAPPoval = true;
                this.roleEnumFlag = false;
                //this.$router.replace({ path: "/roleApprovaladd" });
            },
            //修改
            roleApprovalModify(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '修改操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '修改操作只能选择单条数据进行操作'
                    });
                }else{
                    this.dialogroleAPPovaltitile = '修改角色';
                    this.dialog_roleAPPoval = true;
                    this.roleEnumFlag = true;
                    axios.get(`${this.$apiPath}/api/pm/role/queryRoleById/`+ this.merge[0].roleId).then(res=>{
                        if(res.data.data.status == true){
                            res.data.data.status = '1';
                        }else if(res.data.data.status == false){
                            res.data.data.status = '0';
                        }
                        if(res.data.data.chintBusiness == null){
                            res.data.data.chintBusiness = '';
                        }else {
                            res.data.data.chintBusiness = JSON.stringify(res.data.data.chintBusiness);
                        }
                        if(res.data.data.outGoing == null){
                            res.data.data.outGoing = '';
                        }else {
                            res.data.data.outGoing = JSON.stringify(res.data.data.outGoing);
                        }
                        if(res.data.data.regionLevel == null){
                            res.data.data.regionLevel = '';
                        }else {
                            res.data.data.regionLevel = JSON.stringify(res.data.data.regionLevel);
                        }
                        res.data.data.moduleId = JSON.stringify(res.data.data.moduleId);
                        this.roleAPPovalForm = res.data.data;
                    })
                    //this.$router.replace({ name: 'roleApproval_modify', params: { roleId: this.merge[0].roleId}});
                }
            },
            //删除
            roleApprovalDel(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '删除操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '删除操作只能选择单条数据进行操作'
                    });
                }else{
                    this.$confirm('此操作将对角色进行删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        axios.get(`${this.$apiPath}/api/pm/role/deleteRole/`+ this.merge[0].roleId).then(res=>{
                            if(res.data.success == true){
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.roleApprovalSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                    });
                }
            },
            handleClose() {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.roleApprovalSearch_Multiple()
                    // 动画关闭需要一定的时间
                    this.dialog = false;
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog = false;
                clearTimeout(this.timer);
            },
            handleClosButtonClick(val) {
                this.dialogVisibleMenu=true;
                this.addDialogVisible = true;
                this.roleId=val.index.roleId;
                this.queryMenuByRoleId();
            },
            //查询所有菜单树
            getMenuTree(){
                axios.post(`${this.$apiPath}/api/pm/menu/queryMenusAllTree/`).then(res=>{
                    this.menuTreeList= res.data;
                })
            },
            //查询要回显的菜单树
            queryMenuByRoleId(){
                axios.get(`${this.$apiPath}/api/pm/menu/queryMenuByRoleId/`+ this.roleId).then(res=>{
                    const roleMenu=res.data;
                    const checkedMenuIds=[];
                    for(let i=0;i<roleMenu.length;i++){
                        if(roleMenu!=null && roleMenu.length>0){
                            checkedMenuIds.push(roleMenu[i].menuId);
                            if(roleMenu[i].menuId.split("_").length - 1 == 0){
                                this.expandTreeNodesByDefault.push(roleMenu[i].menuId)
                            }else if(roleMenu[i].menuId.split("_").length - 1 == 3){
                                this.expandTreeNodesByDefault.push(roleMenu[i].menuId.slice(0,-2))
                            }
                        }
                    }
                    this.$refs.tree.setCheckedKeys(checkedMenuIds);
                })
            },
            //新增分配菜单
            handleSaveMenu() {
                const checkedMenu = this.$refs.tree.getCheckedNodes();
                const checkedMenuIds = [];
                for (let i = 0; i < checkedMenu.length; i++) {
                    checkedMenuIds.push(checkedMenu[i].menuId);
                }
                this.$confirm('是否要分配该菜单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let params = new URLSearchParams();
                    params.append("roleId", this.roleId);
                    params.append("menuIds", checkedMenuIds);
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/pm/menu/updateRoleMenus`,
                        data:params
                    }).then(res=> {
                        if(res.data.success == true){
                            this.$message({
                                message: '操作成功',
                                type: 'success'
                            });
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                        this.dialogVisibleMenu = false;
                        this.$refs.tree.setCheckedKeys([])
                    });
                })

            },
            handleClearMenu(){

            }
        }
    }
</script>
<style>
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
</style>
<style scoped>
    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
</style>
